import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCalendar,
  faCalendarAlt,
  faCalendarDays,
  faCalendarWeek,
  faFile,
  faFilePdf,
  faList,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Table from "../components/Table";
import Rapor from "../components/Rapor";
import Ekstre from "../components/Ekstre";

const MainContainer = styled.div`
  // .sign-out {
  //   position: fixed;
  //   bottom: 15px;
  //   font-size: 20px;
  //   border-radius: 30%;
  //   right: 15px;
  //   z-index: 999;
  //   span {
  //     display: none;
  //   }
  // }
  .info-box {
  }
  @media only screen and (max-width: 800px) {
    .sign-out {
      position: relative;
      font-size: 20px;
      bottom: unset;
      font-size: unset;
      right: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      top: unset;
      width: 100%;
      flex-direction: row;
      border-radius: 0%;
      z-index: 999;
      span {
        margin-left: 20px;
        display: block;
      }
    }
    .info-box {
      justify-content: center;
      align-items: center;
      div {
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }
  .info-box .alert-box {
    min-width: 100px;
  }
  @media only screen and (max-width: 800px) {
    .info-box {
      & > div:nth-child(1) {
        width: 100%;
        margin-left: 5%;
        .alert-box {
          width: 100% !important;
          strong {
            font-size: 30px;
          }
        }
      }
      & > div:nth-child(2) {
        width: 100%;
        margin-left: 5%;
        .alert-box:nth-child(3) {
          width: 100% !important;
          strong {
            font-size: 30px;
          }
        }
      }
      .alert-box {
        display: block;
        width: 43%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 20px !important;
        strong {
        }
      }
    }
  }
`;

export default function App({ setLogin, setName, setPass }) {
  const [mode, setMode] = useState("table");
  const [showBasic, setShowBasic] = useState(false);
  const [active, setActive] = useState("0");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [siralama, setSiralama] = useState([]);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState(null);

  const getData = async (
    aller = false,
    st = { startDate: startDate, endDate: endDate }
  ) => {
    setSiralama("loading");
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/stok-durum.php?start=" +
        st.startDate +
        "&end=" +
        st.endDate +
        "&mail=" +
        localStorage.getItem("name") +
        "&webpass=" +
        localStorage.getItem("pass")
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error == false) {
          if (aller) {
            setAllData(res.data);
          } else {
            setData(
              res.data.sort((a, b) => {
                b.Satis_Miktar = b.Satis_Miktar == null ? 0 : b.Satis_Miktar;
                a.Satis_Miktar = a.Satis_Miktar == null ? 0 : a.Satis_Miktar;
                return parseInt(b.Satis_Miktar) - parseInt(a.Satis_Miktar);
              })
            );
          }
        }
      });
    if (aller) {
      return;
    }
    try {
      fetch(
        "https://api.tedarikci.ayakkabipazari.com/cari-siralama.php?start=" +
          st.startDate +
          "&end=" +
          st.endDate +
          "&mail=" +
          localStorage.getItem("name") +
          "&webpass=" +
          localStorage.getItem("pass")
      )
        .then((res) => res.json())
        .then((res) => {
          setSiralama(res.data);
        });
    } catch (error) {
      
    }
  };

  useEffect(() => {
    if (startDate != "" && endDate != "") {
      getData();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (active == "tum" || active == "tums") {
      let today = new Date();
      const sevenDaysAgo = today.getTime() - 3652 * 24 * 60 * 60 * 1000;
      const sevenDaysAgoFormatted = new Date(sevenDaysAgo)
        .toISOString()
        .slice(0, 10);
      setStartDate(sevenDaysAgoFormatted);
      today = new Date();
      const todayFormatted = today.toISOString().slice(0, 10);
      setEndDate(todayFormatted);
    } else {
      let today = new Date();
      const sevenDaysAgo =
        today.getTime() - parseInt(active) * 24 * 60 * 60 * 1000;
      const sevenDaysAgoFormatted = new Date(sevenDaysAgo)
        .toISOString()
        .slice(0, 10);
      setStartDate(sevenDaysAgoFormatted);
      today = new Date();
      const todayFormatted = today.toISOString().slice(0, 10);
      setEndDate(todayFormatted);
    }
    setMode("table");
  }, [active]);

  useEffect(() => {
    if (allData==null) {
      let today = new Date();
      const sevenDaysAgo = today.getTime() - 3652 * 24 * 60 * 60 * 1000;
      const sevenDaysAgoFormatted = new Date(sevenDaysAgo)
        .toISOString()
        .slice(0, 10);
      const start = sevenDaysAgoFormatted;
      today = new Date();
      const todayFormatted = today.toISOString().slice(0, 10);
      const end = todayFormatted;
      getData(true, { startDate: start, endDate: end });
    }
  }, [allData]);

  const getPage=(m)=>{
    switch (m) {
      case "table":
        return (
          <Table
            mode={mode}
            setData={setData}
            data={data}
            siralama={siralama}
            allData={allData}
            active={active}
            startDate={startDate}
            endDate={endDate}
          />
        );
        break;
      case "rapor":
        return (
          <Rapor
            mode={mode}
            setData={setData}
            siralama={siralama}
            data={data}
            allData={allData}
            active={active}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case "ekstre":
        return (
          <Ekstre
            mode={mode}
            setData={setData}
            siralama={siralama}
            data={data}
            allData={allData}
            active={active}
            startDate={startDate}
            endDate={endDate}
          />
        );
      default:
        break;
    }
  }
  return (
    <MainContainer>
      <MDBNavbar className="no-print" expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarBrand href="#">Ulusoy</MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowBasic(!showBasic)}
          >
            <span style={{ marginRight: 10 }}>MENU</span>
            <FontAwesomeIcon icon={faBars} />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => setActive("0")}
                    className={active == "0" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faCalendarWeek}
                    />
                    Bugün
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => setActive("7")}
                    className={active == "7" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faCalendarDays}
                    />
                    Son 7 Gün
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => setActive("30")}
                    className={active == "30" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faCalendarAlt}
                    />
                    Son 30 Gün
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => setActive("tum")}
                    className={active == "tum" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faList}
                    />
                    Tümü
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => setActive("tums")}
                    className={active == "tums" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faList}
                    />
                    Tüm Ürünler
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      setMode(mode == "table" ? "rapor" : "table");
                      //setActive("");
                    }}
                    className={mode == "rapor" ? "btn-success" : "btn-warning"}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faFile}
                    />
                    Raporlar
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() =>
                      window.open(
                        "http://www.verimrapor.com/ayakkabipazari/login.php",
                        "_blank"
                      )
                    }
                    className={"btn-info"}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faFilePdf}
                    />
                    Cari Ekstre
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      localStorage.removeItem("name");
                      localStorage.removeItem("pass");
                      localStorage.removeItem("allData");
                      setTimeout(() => {
                        setName("");
                        setPass("");
                        setLogin(false);
                      }, 500);
                    }}
                    className="btn-danger sign-out"
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faSignOut}
                    />
                    <span>Çıkış Yap</span>
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>

            <form
              className="d-flex input-group w-auto"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="date"
                className="form-control"
                placeholder="Type query"
                aria-label="Search"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control"
                placeholder="Type query"
                aria-label="Search"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <MDBBtn
                onClick={(e) => {
                  getData();
                  setActive("yok");
                }}
                color="primary"
              >
                GETİR
              </MDBBtn>
            </form>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      

      {getPage(mode)}
      
      
    </MainContainer>
  );
}
