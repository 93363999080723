import React,{useEffect} from 'react'

import Alert from "react-bootstrap/Alert";
import { NumericFormat } from "react-number-format";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Tables({ allData, active,startDate,endDate }) {
  const [search, setSearch] = React.useState({ISLEMTIPI:'',ALACAK:'',BORC:'',TARIH:'',ACIKLAMA:''});
  const [data,setData]=React.useState(null);
  const [sorted, setSorted] = React.useState({ row: 4, mode: "desc" });
  const [oldSorted, setOldSorted] = React.useState("4");
  const getData=async()=>{
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/cari-ekstre.php?start=" +
        startDate +
        "&end=" +
        endDate +
        "&mail=" +
        localStorage.getItem("name") +
        "&webpass=" +
        localStorage.getItem("pass")
    ).then((res) => res.json())
      .then(async(res) => {
        if (res.error == false) { 
          console.log(res.data)
        
          sortData(4, "desc",res.data.map((x,index)=>{
            return { ...x, row: (x.TRANNO)};
          }));

        }
      
      });
  }
  useEffect(() => {
    if(data==null){
      getData()

    }
  }, [data]);

  const sortData = async (col, mode = "asc",ndata=data) => {
    let sortedeb = ndata.slice().sort((a, b) => {
      a.ALACAK = a.ALACAK == null ? 0 : a.ALACAK;
      a.BORC = a.BORC == null ? 0 : a.BORC;
      b.ALACAK = b.ALACAK == null || b.ALACAK==undefined ? 0 : b.ALACAK;
      b.BORC = b.BORC == null ? 0 : b.BORC;
      if(a.PARABIRIMI!="TL"){
        a.ALACAK = a.DOVIZALACAK == undefined ? 0 : a.DOVIZALACAK;
        a.BORC = a.DOVIZBORC == undefined ? 0 : a.DOVIZBORC;
      }
      if (b.PARABIRIMI != "TL") {
        b.ALACAK = b.DOVIZALACAK == undefined ? 0 : b.DOVIZALACAK;
        b.BORC = b.DOVIZBORC == undefined ? 0 : b.DOVIZBORC;
      }
      a.ACIKLAMA = a.ACIKLAMA == null ? "" : a.ACIKLAMA;
      b.ACIKLAMA = b.ACIKLAMA == null ? "" : b.ACIKLAMA;
      if (mode != "asc") {
        switch (col) {
          case 0:
            return parseInt(b.row) - parseInt(a.row);
            break;
          case 2:
            return parseInt(b.BORC) - parseInt(a.BORC);
            break;
          case 3:
            return (b.ALACAK) - (a.ALACAK);
            break;
          case 4:
            return new Date(b.TARIH) - new Date(a.TARIH);
            break;
          case 1:
            return b.ISLEMTIPI.localeCompare(a.ISLEMTIPI);
            break;
          case 55:
            return b.ACIKLAMA.localeCompare(a.ACIKLAMA);
            break;
          case 6:
            return a.row - b.row;
            break;
          case 5:
            return parseInt(a.BAKIYE) - parseInt(b.BAKIYE);
            break;
          default:
            break;
        }
      } else {
        switch (col) {
          case 0:
            return parseInt(a.row) - parseInt(b.row);
            break;
          case 2:
            return parseInt(a.BORC) - parseInt(b.BORC);
            break;
          case 3:
            return (a.ALACAK) - (b.ALACAK);
            break;
          case 4:
            return new Date(a.TARIH) - new Date(b.TARIH);
            break;
          case 1:
            return a.ISLEMTIPI.localeCompare(b.ISLEMTIPI);
            break;
          case 55:
            return b.ACIKLAMA.localeCompare(a.ACIKLAMA);
            break;
          case 5:
            return parseInt(b.BAKIYE) - parseInt(a.BAKIYE);
            break;
          case 6:
            return b.row - a.row;
            break;
          default:
            break;
        }
      }
    });
    setOldSorted({ row: col, mode: mode });
    setSorted({ row: col, mode: mode });
    setData(sortedeb);
  };
  const filtered=(data)=>{
    let newdata= data.filter(x=>{
      if(search.ISLEMTIPI!=''){
        if(x.ISLEMTIPI.toLowerCase().includes(search.ISLEMTIPI.toLowerCase())==false){
          return false
        }
      }
      if(search.BORC!=''){
        if (((x.BORC==null ? x.DOVIZBORC==null?0:x.DOVIZBORC:x.BORC)+"").toLowerCase().includes(search.BORC.toLowerCase()) == false) {
          return false;
        }
      }
      if(search.ALACAK!=''){
        if (((x.ALACAK==null ? x.DOVIZALACAK==null?0:x.DOVIZALACAK:x.ALACAK)+"").toLowerCase().includes(search.ALACAK.toLowerCase()) == false) {
          return false;
        }
      }
      if(search.TARIH!=''){
        if ((x.TARIH+"").toLowerCase().includes(search.TARIH.toLowerCase()) == false) {
          return false;
        }
      }
      if(search.ACIKLAMA!=''){
        if ((x.ACIKLAMA+"").toLowerCase().includes(search.ACIKLAMA.toLowerCase()) == false) {
          return false;
        }
      }
      return true
      })
    return newdata
  }
  const getBakiye=()=>{
    return (
      (filtered(data).reduce((acc, eleman) => {
        if (eleman.DOVIZBORC != null) {
          const fiyat = eleman.DOVIZBORC;
          return acc + fiyat * 1 * 1;
        }
        if (eleman.BORC) {
          const fiyat = eleman.BORC;
          return acc + fiyat * 1 * 1;
        }
        return acc;
      }, 0) -
        filtered(data).reduce((acc, eleman) => {
          if (eleman.DOVIZALACAK != null) {
            const fiyat = eleman.DOVIZALACAK;
            return acc + fiyat * 1 * 1;
          }
          if (eleman.ALACAK) {
            const fiyat = eleman.ALACAK;
            return acc + fiyat * 1 * 1;
          }
          return acc;
        }, 0)) *
      -1
    );
  }
  const getBakiyeNet = () => {
    return (
      getBakiye() -
      allData
        .reduce((acc, eleman) => {
          try {
            const fiyat =
              parseFloat(
                eleman.ALISFIYATI == null || eleman.ALISFIYATI == ""
                  ? 0
                  : eleman.ALISFIYATI
              ) *
              parseInt(
                eleman.Mevcut == null || eleman.Mevcut == "" ? 0 : eleman.Mevcut
              );
            if (eleman.ALISFIYATI != null && eleman.ALISFIYATI > 5000) {
              console.log(
                eleman.StokKodu,
                eleman.Mevcut,
                eleman.ALISFIYATI,
                fiyat
              );
            }
            return acc + parseFloat(fiyat == null ? 0 : fiyat) * 1;
          } catch (error) {
            console.log(error);
          }
          return acc;
        }, 0)
        .toFixed(2)
    );
  };
  const getdateFormat=t=>{
    const tarih = new Date(t);
    let gun = tarih.getDate();
    let ay = tarih.getMonth() + 1; // JavaScript'te ay endeksi 0'dan başlar, bu yüzden +1 eklenir
    const yil = tarih.getFullYear();
    gun = (gun+"").length === 1 ? ("0" + gun) : gun;
    ay = (ay+"").length === 1 ? ("0" + ay) : ay;
    return <>{gun + "." + ay + "." + yil}</>;
  }
  function formatTL(amount) {
    // Sayıyı 2 ondalık basamağa yuvarla
    const fixedAmount = amount.toFixed(2);

    // Ondalık ve tam sayı kısmını ayır
    let [integerPart, decimalPart] = fixedAmount.split(".");

    // Tam sayı kısmında binlik ayraçları ekle (nokta ile)
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Formatlanmış hali geri döndür (TL sembolü ile)
    return integerPart + "," + decimalPart + "";
  }
  return (
    <>
      {allData != null && data != null && (
        <div
          className="ekstre-box"
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            paddingLeft: 10,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: 20,
              flexWrap: "wrap",
              marginBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            className="info-box2"
          >
            <Alert
              className="alert-box2"
              style={{ marginRight: 20, minWidth: 100 }}
              variant="success"
            >
              <span
                style={{ position: "absolute", left: 5, top: 4, fontSize: 12 }}
              >
                Toplam Çift Satış
              </span>
              <strong>
                {allData.reduce((acc, eleman) => {
                  if (eleman.hasOwnProperty("Satis_Miktar")) {
                    const fiyat = eleman["Satis_Miktar"];
                    return acc + fiyat * 1 * 1;
                  }
                  return acc;
                }, 0)}{" "}
              </strong>{" "}
            </Alert>
            <Alert
              className="alert-box2"
              style={{ marginRight: 20, minWidth: 100 }}
              variant="success"
            >
              <span
                style={{ position: "absolute", left: 5, top: 4, fontSize: 12 }}
              >
                Mevcut
              </span>
              <strong>
                {allData.reduce((acc, eleman) => {
                  if (eleman.hasOwnProperty("Mevcut")) {
                    const fiyat = eleman["Mevcut"];
                    return acc + fiyat * 1 * 1;
                  }
                  return acc;
                }, 0)}
              </strong>{" "}
            </Alert>
            <Alert
              className="alert-box2"
              style={{ marginRight: 20, minWidth: 100 }}
              variant="success"
            >
              <span
                style={{ position: "absolute", left: 5, top: 4, fontSize: 12 }}
              >
                Stok Tutarı
              </span>
              <strong>
                {formatTL(
                  allData.reduce((acc, eleman) => {
                    try {
                      const fiyat =
                        parseFloat(
                          eleman.ALISFIYATI == null || eleman.ALISFIYATI == ""
                            ? 0
                            : eleman.ALISFIYATI
                        ) *
                        parseInt(
                          eleman.Mevcut == null || eleman.Mevcut == ""
                            ? 0
                            : eleman.Mevcut
                        );
                      if (
                        eleman.ALISFIYATI != null &&
                        eleman.ALISFIYATI > 5000
                      ) {
                        console.log(
                          eleman.StokKodu,
                          eleman.Mevcut,
                          eleman.ALISFIYATI,
                          fiyat
                        );
                      }
                      return acc + parseFloat(fiyat == null ? 0 : fiyat) * 1;
                    } catch (error) {
                      console.log(error);
                    }
                    return acc;
                  }, 0)
                )} ₺
              </strong>{" "}
            </Alert>
          </div>
          <div
            className="info-box2"
            style={{
              marginTop: 20,
              flexWrap: "wrap",
              marginBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Alert
              className="alert-box2"
              style={{ marginRight: 20 }}
              variant="secondary"
            >
              <span
                style={{ position: "absolute", left: 5, top: 4, fontSize: 12 }}
              >
                Toplam Borç
              </span>
              <strong>
                {formatTL(
                  filtered(data).reduce((acc, eleman) => {
                    if (eleman.DOVIZBORC != null) {
                      const fiyat = eleman.DOVIZBORC;
                      return acc + fiyat * 1 * 1;
                    }
                    if (eleman.BORC) {
                      const fiyat = eleman.BORC;
                      return acc + fiyat * 1 * 1;
                    }
                    return acc;
                  }, 0)
                )}
                ₺
              </strong>{" "}
            </Alert>
            <Alert
              className="alert-box2"
              style={{ marginRight: 20 }}
              variant="warning"
            >
              <span
                style={{ position: "absolute", left: 5, top: 4, fontSize: 12 }}
              >
                Toplam Alacak
              </span>
              <strong>
                {formatTL(
                  filtered(data).reduce((acc, eleman) => {
                    if (eleman.DOVIZALACAK != null) {
                      const fiyat = eleman.DOVIZALACAK;
                      return acc + fiyat * 1 * 1;
                    }
                    if (eleman.ALACAK) {
                      const fiyat = eleman.ALACAK;
                      return acc + fiyat * 1 * 1;
                    }
                    return acc;
                  }, 0)
                )}
                ₺
              </strong>{" "}
            </Alert>
            <Alert
              className="alert-box2"
              style={{ marginRight: 20 }}
              variant={getBakiye() < 0 ? "danger" : "success"}
            >
              <span
                style={{ position: "absolute", left: 5, top: 4, fontSize: 12 }}
              >
                Bakiye
              </span>
              <strong>
                {formatTL(getBakiye())}
                {" ₺"}
              </strong>{" "}
              {getBakiye() > 0 ? "Alacak" : "Borç"}
            </Alert>
            <Alert
              className="alert-box2"
              style={{ marginRight: 20 }}
              variant={getBakiyeNet() < 0 ? "danger" : "success"}
            >
              <span
                style={{ position: "absolute", left: 5, top: 4, fontSize: 12 }}
              >
                Net Bakiye
              </span>
              <strong>
                {formatTL(getBakiyeNet())}
                {" ₺"}
              </strong>{" "}
            </Alert>
          </div>
        </div>
      )}
      <div className="input-list">
        <input
          type="text"
          placeholder="Tarih Ara"
          className="form-control no-print"
          value={search.TARIH}
          onChange={(e) => setSearch({ ...search, TARIH: e.target.value })}
        />
        <input
          type="text"
          placeholder="İşlem Tipi Ara"
          className="form-control no-print"
          value={search.ISLEMTIPI}
          onChange={(e) => setSearch({ ...search, ISLEMTIPI: e.target.value })}
        />
        <input
          type="text"
          placeholder="Borç Tutar Ara"
          className="form-control no-print"
          value={search.BORC}
          onChange={(e) => setSearch({ ...search, BORC: e.target.value })}
        />
        <input
          type="text"
          placeholder="Alacak Tutar Ara"
          className="form-control no-print"
          value={search.ALACAK}
          onChange={(e) => setSearch({ ...search, ALACAK: e.target.value })}
        />
        <input
          type="text"
          placeholder="Açıklama Ara"
          className="form-control no-print"
          value={search.ACIKLAMA}
          onChange={(e) => setSearch({ ...search, ACIKLAMA: e.target.value })}
        />
      </div>
      <div className="table-container">
        {data && (
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th
                  onClick={(e) =>
                    sortData(
                      4,
                      sorted.row == 4 && sorted.mode == "asc" ? "desc" : "asc"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  Tarih
                  {sorted.row == 4 ? (
                    sorted.mode != "desc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )
                  ) : (
                    <></>
                  )}
                </th>
                <th
                  onClick={(e) =>
                    sortData(
                      6,
                      sorted.row == 6 && sorted.mode == "asc" ? "desc" : "asc"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  Fiş No
                  {sorted.row == 6 ? (
                    sorted.mode != "desc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )
                  ) : (
                    <></>
                  )}
                </th>

                <th
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    sortData(
                      1,
                      sorted.row == 1 && sorted.mode == "asc" ? "desc" : "asc"
                    )
                  }
                >
                  İşlem Tipi
                  {sorted.row == 1 ? (
                    sorted.mode != "desc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )
                  ) : (
                    <></>
                  )}
                </th>

                <th
                  onClick={(e) =>
                    sortData(
                      2,
                      sorted.row == 2 && sorted.mode == "asc" ? "desc" : "asc"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  BORC
                  {sorted.row == 2 ? (
                    sorted.mode != "desc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )
                  ) : (
                    <></>
                  )}
                </th>
                <th
                  onClick={(e) =>
                    sortData(
                      3,
                      sorted.row == 3 && sorted.mode == "asc" ? "desc" : "asc"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  ALACAK
                  {sorted.row == 3 ? (
                    sorted.mode != "desc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )
                  ) : (
                    <></>
                  )}
                </th>
                <th
                  onClick={(e) =>
                    sortData(
                      5,
                      sorted.row == 5 && sorted.mode == "asc" ? "desc" : "asc"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  BAKIYE
                  {sorted.row == 5 ? (
                    sorted.mode != "desc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )
                  ) : (
                    <></>
                  )}
                </th>
                <th
                  onClick={(e) =>
                    sortData(
                      55,
                      sorted.row == 55 && sorted.mode == "asc" ? "desc" : "asc"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  Açıklama
                  {sorted.row == 55 ? (
                    sorted.mode != "desc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )
                  ) : (
                    <></>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered(data).map((x, index) => {
                return (
                  <tr key={"tab-" + index}>
                    <td>{getdateFormat(x.TARIH)}</td>
                    <td>{x.row}</td>
                    <td>{x.ISLEMTIPI}</td>
                    <td>
                      {formatTL(
                        x.ALACAK == "TL"
                          ? parseFloat(x.BORC == null ? 0 : x.BORC)
                          : parseFloat(x.DOVIZBORC == null ? 0 : x.DOVIZBORC)
                      )}{" "}
                      ₺
                    </td>
                    <td>
                      {formatTL(
                        x.ALACAK == "TL"
                          ? parseFloat(x.ALACAK == null ? 0 : x.ALACAK)
                          : parseFloat(
                              x.DOVIZALACAK == null ? 0 : x.DOVIZALACAK
                            )
                      )}{" "}
                      ₺
                    </td>
                    <td style={{ maxWidth: 150 }}>{formatTL(x.BAKIYE)} ₺</td>
                    <td style={{ maxWidth: 150 }}>{x.ACIKLAMA}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}
