import {
  faArrowDown,
  faArrowUp,
  faEllipsisV,
  faLineChart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";

import Alert from "react-bootstrap/Alert";
import { NumericFormat } from "react-number-format";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Column } from "primereact/column";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";

import "react-data-table-component-extensions/dist/index.css";
import { MainContainer } from "../styled";
export default function Tables({
  setData,
  data,
  getData,
  getAdmin,
  setStok,
  siralama,
  active,
  startDate,
  endDate,
}) {
  const [search, setSearch] = React.useState("");
  const [sorted, setSorted] = React.useState({ row: 0, mode: "desc" });
  const [oldSorted, setOldSorted] = React.useState("0");

  const [selectedAsorties, setSelectedAsorties] = React.useState([]);
  const [asortiMenu, setAsortiMenu] = React.useState(false);
  const [mevcutMenu, setMevcutMenu] = React.useState(false);
  const [satisMenu, setSatisMenu] = React.useState(false);

  const [minMevcut, setMinMevcut] = React.useState(null);
  const [maxMevcut, setMaxMevcut] = React.useState(null);

  const [minSatis, setMinSatis] = React.useState(null);
  const [maxSatis, setMaxSatis] = React.useState(null);

  const [selectedSezon, setSelectedSezon] = React.useState([]);
  const [sezonMenu, setSezonMenu] = React.useState(false);



  const filtered = (mdata) => {
    let newdata = mdata.filter((x) => {
      return x.StokAdi.toLowerCase().includes(search.toLowerCase()) || x.StokKodu.toLowerCase().includes(search.toLowerCase());
    });
    if (active != "tums") {
      newdata = newdata.filter((x) => {
        return parseInt(x.Satis_Miktar) > 0;
      });
    }
    return newdata;
  };
  const finalFilter = (mdata) => {
    let newdata = mdata.filter((x) => {
      if (selectedAsorties.length > 0 && !selectedAsorties.includes(x.Asorti)) {
        return false;
      }
      if (selectedSezon.length > 0 && !selectedSezon.includes(x.Sezon)) {
        return false;
      }
      if (
        minMevcut != null &&
        minMevcut != "" &&
        parseInt(x.Mevcut) < minMevcut
      ) {
        return false;
      }
      if (
        maxMevcut != null &&
        maxMevcut != "" &&
        parseInt(x.Mevcut) > maxMevcut
      ) {
        return false;
      }
      if (
        minSatis != null &&
        minSatis != "" &&
        parseInt(x.Satis_Miktar) < minSatis
      ) {
        return false;
      }
      if (
        maxSatis != null &&
        maxSatis != "" &&
        parseInt(x.Satis_Miktar) > maxSatis
      ) {
        return false;
      }
      return true;
    });
    return newdata;
  };

  const sortData = async (col, mode = "asc") => {
    
    let sortedeb = data.slice().sort((a, b) => {
      b.Satis_Miktar = b.Satis_Miktar == null ? 0 : b.Satis_Miktar;
      a.Satis_Miktar = a.Satis_Miktar == null ? 0 : a.Satis_Miktar;
      b.Son_Satis_Gun = b.Son_Satis_Gun == null ? 0 : parseInt(b.Son_Satis_Gun);
      a.Son_Satis_Gun = a.Son_Satis_Gun == null ? 0 : parseInt(a.Son_Satis_Gun);

      b.Son_Hareket_Gun =
        b.Son_Hareket_Gun == null ? 0 : parseInt(b.Son_Hareket_Gun);
      a.Son_Hareket_Gun = a.Son_Hareket_Gun == null ? 0 : parseInt(a.Son_Hareket_Gun);


      a.Mevcut = a.Mevcut == null ? 0 : a.Mevcut;
      b.Mevcut = b.Mevcut == null ? 0 : b.Mevcut;
      b.Puan = b.Puan == null ? 0 : b.Puan;
      a.Puan = a.Puan == null ? 0 : a.Puan;
      a.Asorti = a.Asorti == null ? "" : a.Asorti;
      b.Asorti = b.Asorti == null ? "" : b.Asorti;
      a.Sezon = a.Sezon == null ? "" : a.Sezon;
      b.Sezon = b.Sezon == null ? "" : b.Sezon;
      if (mode != "asc") {
        
        switch (col) {
          case 0:
            return parseInt(b.Satis_Miktar) - parseInt(a.Satis_Miktar);
            break;
          case 1:
            return parseInt(b.Mevcut) - parseInt(a.Mevcut);
            break;
          case 2:
            return parseInt(b.Toplam_Cikan) - parseInt(a.Toplam_Cikan);
            break;
          case 3:
            return parseInt(b.Son_Satis_Gun) - parseInt(a.Son_Satis_Gun);
            break;
          case 34:
            return parseInt(b.Son_Hareket_Gun) - parseInt(a.Son_Hareket_Gun);
            break;
          case 4:
            return b.StokAdi.localeCompare(a.StokAdi);
            break;
          case 5:
            return parseFloat(b.StokKodu) - parseInt(a.StokKodu);
            break;
          case 8:
            return parseInt(b.Puan) - parseInt(a.Puan);
            break;
          case 11:
            return b.Asorti.localeCompare(a.Asorti);
            break;
          case 22:
            return b.Asorti.localeCompare(a.Asorti);
            break;
          case 52:
            return b.Sezon.localeCompare(a.Sezon);
            break;
          default:
            break;
        }
      } else {
        switch (col) {
          case 0:
            return parseInt(a.Satis_Miktar) - parseInt(b.Satis_Miktar);
            break;
          case 1:
            return parseInt(a.Mevcut) - parseInt(b.Mevcut);
            break;
          case 2:
            return parseInt(a.Toplam_Cikan) - parseInt(b.Toplam_Cikan);
            break;
          case 3:
            return parseInt(a.Son_Satis_Gun) - parseInt(b.Son_Satis_Gun);
            break;
          case 34:
            return parseInt(a.Son_Hareket_Gun) - parseInt(b.Son_Hareket_Gun);
            break;
          case 4:
            return a.StokAdi.toLowerCase().localeCompare(
              b.StokAdi.toLowerCase()
            );
            break;
          case 5:
            return parseFloat(a.StokKodu) - parseInt(b.StokKodu);
            break;
          case 8:
            return parseInt(a.Puan) - parseInt(b.Puan);
            break;
          case 11:
            return a.Asorti.localeCompare(b.Asorti);
            break;
          case 22:
            return a.Asorti.localeCompare(b.Asorti);
            break;
          case 52:
            return a.Sezon.localeCompare(b.Sezon);
            break;
          default:
            break;
        }
      }
    });
    setOldSorted({ row: col, mode: mode });
    setSorted({ row: col, mode: mode });
    setData(sortedeb);
  };

  React.useEffect(() => {
    setSorted({ row: 0, mode: "desc" });
  }, [active]);

  const [popup, setPopup] = React.useState(false);
  const openImage = (id) => {
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/stok-resim.php?id=" +
        id +
        "&mail=" +
        localStorage.getItem("name") +
        "&webpass=" +
        localStorage.getItem("pass")
    )
      .then((res) => res.json())
      .then(async (res) => {
        setPopup(res.data);
      });
  };


  const columns = [
    {
      name: "Görsel",
      selector: (row) => row.DosyaAdi,
      cell: (row) =>
        row.DosyaAdi ? (
          <img
            style={{ cursor: "pointer" }}
            onClick={() => openImage(row.Barkod)}
            src={row.DosyaAdi}
            height={80}
            width={80}
            alt={row.StokAdi}
          />
        ) : (
          "Görsel Yok"
        ),
      // hide: "sm",
      width: "100px",
    },
    {
      name: "S.Kodu",
      selector: (row) => <p style={{ fontSize: 11 }}>{row.StokKodu}</p>,
      sortable: true,
      width: "130px",
    },
    {
      name: "Stok Adı",
      selector: (row) => row.StokAdi,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {row.StokAdi.replace(/_/g, " ")}
          <span
            style={{ cursor: "pointer", marginLeft: 30 }}
            onClick={() => {
              setStok(row);
            }}
          >
            <FontAwesomeIcon icon={faLineChart} /> Grafik
          </span>
        </div>
      ),
      minWidth: "200px",
    },

    {
      name: "Renk",
      selector: (row) => (
        <p
          style={{
            fontSize: 11,
            display: "flex",
            flexWrap: "wrap",
            textWrap: "wrap",
          }}
        >
          {row.Renk.replace(/_/g, " ")}
        </p>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: (
        <>
          Sezon
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: 7,
              width: 10,
              fontSize: 20,
            }}
            icon={faEllipsisV}
            onClick={(e) => {
              e.stopPropagation();
              setSezonMenu(!sezonMenu);
            }}
          />
          <div
            style={{
              width: 200,
              position: "absolute",
              right: 0,
              top: 50,
              display: sezonMenu ? "block" : "none",
              zIndex: 9999999,
              backgroundColor: "white",
              padding: 10,
              borderRadius: 5,
              border: "1px solid black",
            }}
          >
            {data != null && data.length > 0 && filtered(data).length > 0 ? (
              <>
                {[...new Set(filtered(data).map((item) => item.Sezon))].map(
                  (x) => {
                    if (x == null || x == "") {
                      return;
                    }
                    return (
                      <label
                        key={x}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          userSelect: "none",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={selectedSezon.includes(x)}
                          onChange={(e) => {
                            e.stopPropagation();
                            if (e.target.checked) {
                              setSelectedSezon([...selectedSezon, x]);
                            } else {
                              setSelectedSezon(
                                selectedSezon.filter((y) => y != x)
                              );
                            }
                          }}
                          style={{ marginRight: 10 }}
                        />
                        {x}
                      </label>
                    );
                  }
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ),
      selector: (row) => row.Sezon,
      sortable: true,
      width: "150px",
    },
    {
      name: (
        <div>
          Asorti
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: 7,
              width: 10,
              fontSize: 20,
            }}
            icon={faEllipsisV}
            onClick={(e) => {
              e.stopPropagation();
              setAsortiMenu(!asortiMenu);
            }}
          />
          <div
            style={{
              width: 200,
              position: "absolute",
              right: 0,
              top: 50,
              display: asortiMenu ? "block" : "none",
              zIndex: 9999999,
              backgroundColor: "white",
              padding: 10,
              borderRadius: 5,
              border: "1px solid black",
            }}
          >
            {data != null && data.length > 0 && filtered(data).length > 0 ? (
              <>
                {[...new Set(filtered(data).map((item) => item.Asorti))].map(
                  (x) => {
                    if (x == null || x == "") {
                      return;
                    }
                    return (
                      <label
                        key={x}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          userSelect: "none",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={selectedAsorties.includes(x)}
                          onChange={(e) => {
                            e.stopPropagation();
                            if (e.target.checked) {
                              setSelectedAsorties([...selectedAsorties, x]);
                            } else {
                              setSelectedAsorties(
                                selectedAsorties.filter((y) => y != x)
                              );
                            }
                          }}
                          style={{ marginRight: 10 }}
                        />
                        {x}
                      </label>
                    );
                  }
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ),
      selector: (row) => <>{row.Asorti}</>,
      sortable: true,
      width: "130px",
    },
    {
      name: (
        <>
          Mevcut{" "}
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: 7,
              width: 10,
              fontSize: 20,
            }}
            icon={faEllipsisV}
            onClick={(e) => {
              e.stopPropagation();
              setMevcutMenu(!mevcutMenu);
            }}
          />
          <div
            style={{
              width: 200,
              position: "absolute",
              right: 0,
              top: 50,
              display: mevcutMenu ? "block" : "none",
              zIndex: 9999999,
              backgroundColor: "white",
              padding: 10,
              borderRadius: 5,
              border: "1px solid black",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <input
              type="number"
              value={minMevcut}
              onChange={(e) => {
                setMinMevcut(e.target.value < 0 ? 0 : e.target.value);
              }}
              placeholder="En az mevcut"
            />
            <input
              type="number"
              value={maxMevcut}
              onChange={(e) => {
                setMaxMevcut(e.target.value < 0 ? 0 : e.target.value);
              }}
              placeholder="En fazla mevcut"
            />
          </div>
        </>
      ),
      selector: (row) => parseInt(row.Mevcut || 0),
      sortable: true,
      width: "140px",
    },
    {
      name: (
        <>
          Satış{" "}
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: 7,
              width: 10,
              fontSize: 20,
            }}
            icon={faEllipsisV}
            onClick={(e) => {
              e.stopPropagation();
              setSatisMenu(!satisMenu);
            }}
          />
          <div
            style={{
              width: 200,
              position: "absolute",
              right: 0,
              top: 50,
              display: satisMenu ? "block" : "none",
              zIndex: 9999999,
              backgroundColor: "white",
              padding: 10,
              borderRadius: 5,
              border: "1px solid black",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <input
              type="number"
              value={minSatis}
              onChange={(e) => {
                setMinSatis(e.target.value < 0 ? 0 : e.target.value);
              }}
              placeholder="En az satış"
            />
            <input
              type="number"
              value={maxSatis}
              onChange={(e) => {
                setMaxSatis(e.target.value < 0 ? 0 : e.target.value);
              }}
              placeholder="En fazla satış"
            />
          </div>
        </>
      ),
      selector: (row) => parseInt(row.Satis_Miktar || 0),
      sortable: true,
      width: "140px",
    },
    {
      name: "Toplam Satış",
      selector: (row) => parseInt(row.Toplam_Cikan || 0),
      sortable: true,
      width: "140px",
    },
    {
      name: "(Gün)Son Satış",
      selector: (row) => parseInt(row.Son_Satis_Gun || 0),
      sortable: true,
      width: "140px",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{row.Son_Satis_Gun || "-"}</span>
          <span style={{ fontSize: "24px" }}>
            {row.Mevcut <= 0
              ? "👋🏿"
              : !row.Son_Satis_Gun
              ? "😱"
              : row.Son_Satis_Gun <= 8
              ? "😂"
              : row.Son_Satis_Gun <= 21
              ? "😑"
              : row.Son_Satis_Gun <= 31
              ? "🥵"
              : "🥵"}
          </span>
        </div>
      ),
    },
    {
      name: "(Gün)Son Hareket",
      selector: (row) => parseInt(row.Son_Hareket_Gun || 0),
      sortable: true,
      width: "150px",
    },
    {
      name: "Durum",
      selector: (row) => row.Mevcut,
      sortable: true,
      width: "120px",
      cell: (row) =>
        row.Mevcut < 8 ? (
          <span style={{ color: "red" }}>Stokta Yok!</span>
        ) : (
          <span style={{ color: "green" }}>Mevcut</span>
        ),
    },
  ];
  
  
  return (
    <>
      {popup != false ? (
        <>
          <div onClick={() => setPopup(false)} className="popup"></div>
          <div className="popup-container">
            <Slide transitionDuration={300} className="box-slide">
              {popup.map((x) => {
                return (
                  <div className="each-slide-effect">
                    <img src={`${x.DosyaAdi}`} />
                  </div>
                );
              })}
            </Slide>
          </div>
        </>
      ) : (
        <></>
      )}
      <div style={{ marginTop: 20, marginBottom: 20, display: "flex" }}>
        <input
          type="text"
          placeholder="Stok Adı veya Kodu ile Ara"
          className="form-control no-print"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        
      </div> 
      <div
        className="info-box"
        style={{
          marginTop: 20,
          marginBottom: 20,
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginTop: 20,
            flexWrap: "wrap",
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
        <div
          style={{
            marginTop: 20,
            flexWrap: "wrap",
            marginBottom: 20,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Alert
            className="alert-box"
            style={{ marginRight: 20, fontSize: 22 }}
            variant="warning"
          >
            <span
              style={{
                position: "absolute",
                left: 5,
                top: 4,
                fontSize: 12,
              }}
            >
              {active == "yok"
                ? startDate + " - " + endDate + ""
                : active == "tums"
                ? "Tüm Çift Ürünler"
                : active == "tum"
                ? "Tüm Çift Satışlar  "
                : active == "0"
                ? "Bugün Çift Satış"
                : "Son " + active + " Gün Çift Satış"}
            </span>
            <strong>
              {filtered(data).reduce((acc, eleman) => {
                if (active == "tums") {
                  const fiyat = eleman["Mevcut"];
                  return acc + fiyat * 1 * 1;
                }
                if (eleman.hasOwnProperty("Satis_Miktar")) {
                  const fiyat = eleman["Satis_Miktar"];
                  return acc + fiyat * 1 * 1;
                }
                return acc;
              }, 0)}{" "}
            </strong>{" "}
          </Alert>
          <Alert
            className="alert-box"
            style={{ marginRight: 20, fontSize: 22 }}
            variant="danger"
          >
            <span
              style={{
                position: "absolute",
                left: 5,
                top: 4,
                fontSize: 12,
              }}
            >
              Sıralamanız
            </span>
            <strong>
              {siralama != "loading" ? (
                <>{siralama.siralama}</>
              ) : (
                <span style={{ fontSize: 7 }}>?</span>
              )}
            </strong>{" "}
          </Alert>
        </div>
      </div>
      <MainContainer nodata={finalFilter(filtered(data)).length == 0}>
        {data != null && data.length > 0 && (
          <DataTable
            pagination
            fixedHeader
            columns={columns}
            data={
              finalFilter(filtered(data)).length == 0
                ? [{ StokAdi: "", Renk: "", Mevcut: "" }]
                : finalFilter(filtered(data))
            }
            paginationPerPage={25}
            fixedHeaderScrollHeight="60vh"
            paginationRowsPerPageOptions={[25, 50, 100, 200, 300, 400, 500]}
            paginationComponentOptions={{
              rowsPerPageText: "Sayfa Başına Satır Sayısı:",
              rangeSeparatorText: " / ",
              selectAllRowsItem: true,
            }}
            highlightOnHover
            subHeader
          />
        )}
      </MainContainer>
    </>
  );
}
