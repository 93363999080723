import React, { useState,useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCalendar, faCalendarAlt, faCalendarDays, faCalendarWeek, faFile, faFilePdf, faList, faRefresh, faSignOut } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Table from "../components/Table";
import Rapor from "../components/Rapor";
import Ekstre from "../components/Ekstre";
import Stok from "../components/Stok";
import AdminPage from "../components/Admin";
import {  toast } from "react-toastify";
import logo from '../logo.png'
import PopMenu from "../components/PopMenu";
const MainContainer = styled.div`
  // .sign-out {
  //   position: fixed;
  //   bottom: 15px;
  //   font-size: 20px;
  //   border-radius: 30%;
  //   right: 15px;
  //   z-index: 999;
  //   span {
  //     display: none;
  //   }
  // }
  .info-box {
  }
  @media only screen and (max-width: 800px) {
    .sign-out {
      position: relative;
      font-size: 20px;
      bottom: unset;
      font-size: unset;
      right: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      top: unset;
      width: 100%;
      flex-direction: row;
      border-radius: 0%;
      z-index: 999;
      span {
        margin-left: 20px;
        display: block;
      }
    }
    .info-box {
      justify-content: center;
      align-items: center;
      div {
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }
  .info-box .alert-box {
    min-width: 170px;
  }

  @media only screen and (max-width: 800px) {
    .info-box {
      & > div:nth-child(1) {
        width: 100%;
        margin-left: 5%;
        .alert-box {
          width: 100% !important;
          strong {
            font-size: 30px;
          }
        }
      }
      & > div:nth-child(2) {
        width: 100%;
        margin-left: 5%;
        .alert-box:nth-child(3) {
          width: 100% !important;
          strong {
            font-size: 30px;
          }
        }
      }
      .alert-box {
        display: block;
        width: 43%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 20px !important;
        strong {
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .ekstre-box {
      jusify-content: center;
      align-items: center;
      width: 94% !important;
      margin-left: 5%;
      .info-box2 {
        .alert-box2 {
          jusify-content: center;
          align-items: center;
          width: 100% !important;

          div {
            width: 100% !important;
            * {
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .hide-btn {
  }
  .hide-form {
    display: none !important;
  }
  .show-form {
    position: fixed;
    top: 50px;
    right: 5px;
    z-index: 999;
    padding: 20px;
    box-shadow: 0px 0px 4px 1px #000;
    background-color: #fff;
    display: flex !important;
  }
  @media only screen and (max-width: 800px) {
    .show-form {
      position: relative;
      display: flex !important;
    }
    .hide-form {
      display: flex !important;
    }
    .hide-btn {
      display: none !important;
    }
    .show-btn {
      display: none !important;
    }
  }
  .nav-item {
    a {
      padding-left: 0px !important;
      margin-right: 2px !important;
      padding-right: 0px !important;
      button {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }
  }
  @media only screen and (max-width: 1150px) {
    .nav-item {
      height: 40px;
      a {
        svg {
          display: none;
        }
        height: 40px;
        padding-left: 0px !important;
        margin-right: 1px !important;
        padding-right: 0px !important;
        button {
          height: 40px;
          padding-top: 2px !important;
          padding-bottom: 2px !important;
        }import PopMenu from '../components/PopMenu';

      }
    }
  }
`;


export default function App({setLogin,setName,setPass,adminOpen,setAdminOpen}) {
  const [formShow, setFormShow] = useState(false);
  const [mode, setMode] = useState("table");
  const [stok, setStok] = useState(null);
  const [showBasic, setShowBasic] = useState(false);
  const [active, setActive] = useState("0");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [siralama, setSiralama] = useState([]);
  const [data, setData] = useState([]);
  const [adminData,setAdminData]=useState((localStorage.getItem("admin")==null ? [] : JSON.parse(localStorage.getItem('admin'))))
  const [allData, setAllData] = useState(null);
  
  const getData=async(aller=false,st={startDate:startDate,endDate:endDate})=>{
    setSiralama("loading")
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/stok-durum.php?start=" +st.startDate+"&end=" +st.endDate+
        "&mail=" +
        localStorage.getItem("name") +
        "&webpass=" +
        localStorage.getItem("pass")
    ).then((res) => res.json())
      .then((res) => {
        if (res.error == false) {
          if (aller) {
            setAllData(res.data);
          } else {
            setData(res.data.sort((a,b)=>{
              b.Satis_Miktar = b.Satis_Miktar == null ? 0 : b.Satis_Miktar;
              a.Satis_Miktar = a.Satis_Miktar == null ? 0 : a.Satis_Miktar;
              return parseInt(b.Satis_Miktar) - parseInt(a.Satis_Miktar);
            }));
          }
        }
      });
      if(aller){
        return
      }
    try {
      fetch(
        "https://api.tedarikci.ayakkabipazari.com/cari-siralama.php?start=" +
          st.startDate +
          "&end=" +
          st.endDate +
          "&mail=" +
          localStorage.getItem("name") +
          "&webpass=" +
          localStorage.getItem("pass")
      )
        .then((res) => res.json())
        .then((res) => {
          setSiralama(res.data);
        }).catch(err=>{
      console.log(err);

        });
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    if (startDate!='' && endDate!='') {
      getData();
    }
  }, [startDate,endDate])

  useEffect(() => {
    if(adminOpen==true){
      setMode('admin')
    }
  }, [adminOpen])


  useEffect(() => {
    if (mode != 'admin') {
      setAdminOpen(false);
    }
  }, [mode]);
  

  useEffect(() => {
    
    if(active=="tum"||active=="tums"){
      let today = new Date();
      const sevenDaysAgo =today.getTime() - ( 3652* 24 * 60 * 60 * 1000);
      const sevenDaysAgoFormatted = new Date(sevenDaysAgo).toISOString().slice(0, 10);
      setStartDate(sevenDaysAgoFormatted);
      today = new Date();
      const todayFormatted = today.toISOString().slice(0, 10);
      setEndDate(todayFormatted);
    }else{
      if(active!="yok"){
        let today = new Date();
        const sevenDaysAgo =
          today.getTime() - parseInt(active) * 24 * 60 * 60 * 1000;
        const sevenDaysAgoFormatted = new Date(sevenDaysAgo)
          .toISOString()
          .slice(0, 10);
        setStartDate(sevenDaysAgoFormatted);
        today = new Date();
        const todayFormatted = today.toISOString().slice(0, 10);
        setEndDate(todayFormatted);
      }
    }
  }, [active]);
  
  useEffect(() => {
    
    if(allData==null ){
      let today = new Date();
      const sevenDaysAgo =today.getTime() - (3652 * 24 * 60 * 60 * 1000);
      const sevenDaysAgoFormatted = new Date(sevenDaysAgo).toISOString().slice(0, 10);
      const start=(sevenDaysAgoFormatted);
      today = new Date();
      const todayFormatted = today.toISOString().slice(0, 10);
      const end=(todayFormatted);
      getData(true,{startDate:start,endDate:end});
    }
    
  }, [allData]);
  const getAdmin=()=>{
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/login.php?mail=" +
        localStorage.getItem("adminName") +
        "&webpass=" +
        localStorage.getItem("adminPass")
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.error != true && res.admin!=null && res.admin!=undefined && res.admin == true) {
          setAdminData(res.data)
          localStorage.setItem("admin", JSON.stringify(res.data));
        }
      });

  }
  const getPage = (m) => {
    switch (m) {
      case "table":
        return (
          <Table
            mode={mode}
            setData={setData}
            getData={getData}
            getAdmin={getAdmin}
            data={data}
            siralama={siralama}
            allData={allData}
            active={active}
            startDate={startDate}
            endDate={endDate}
            setStok={setStok}
          />
        );
        break;
      case "rapor":
        return (
          <Rapor
            mode={mode}
            getData={getData}
            getAdmin={getAdmin}
            setData={setData}
            siralama={siralama}
            data={data}
            allData={allData}
            active={active}
            startDate={startDate}
            endDate={endDate}
            setStok={setStok}
          />
        );
      case "ekstre":
        return (
          <Ekstre
            mode={mode}
            getData={getData}
            setData={setData}
            siralama={siralama}
            data={data}
            allData={allData}
            active={active}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case "stok":
        return (
          <Stok
            mode={mode}
            getData={getData}
            setData={setData}
            siralama={siralama}
            data={data}
            allData={allData}
            active={active}
            startDate={startDate}
            endDate={endDate}
            stok={stok}
            setStok={setStok}
            />
        );
      case "admin":
        return (
          <AdminPage
            adminData={adminData}
            setAdminData={setAdminData}
            mode={mode}
            setData={setData}
            siralama={siralama}
            data={data}
            allData={allData}
            active={active}
            startDate={startDate}
            endDate={endDate}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    
    if(stok!=null){
      setMode('stok')
    }
    
  }, [stok])
  
  return (
    <MainContainer>
      <PopMenu
        setName={setName}
        adminOpen={adminOpen}
        setAdminOpen={setAdminOpen}
        setPass={setPass}
        setLogin={setLogin}
      />

      <MDBNavbar className="no-print" expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarBrand
            onClick={() => {
              setActive("0");
              setMode("table");
            }}
            style={{ fontSize: 11, cursor: "pointer", fontWeight: "bold" }}
          >
            {localStorage.getItem("ACname").substring(0, 12)}.
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowBasic(!showBasic)}
          >
            <span style={{ marginRight: 10 }}>MENU</span>
            <FontAwesomeIcon icon={faBars} />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      setActive("0");
                      setMode("table");
                    }}
                    className={active == "0" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faCalendarWeek}
                    />
                    Bugün
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      setActive("7");
                      setMode("table");
                    }}
                    className={active == "7" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faCalendarDays}
                    />
                    Son 7 Gün
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      setActive("30");
                      setMode("table");
                    }}
                    className={active == "30" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faCalendarAlt}
                    />
                    Son 30 Gün
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      setActive("tum");
                      setMode("table");
                    }}
                    className={active == "tum" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faList}
                    />
                    Tüm Satışlar
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      setActive("tums");
                      setMode("table");
                    }}
                    className={active == "tums" ? "btn-success" : ""}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faList}
                    />
                    Tüm Stoklar
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      setMode("rapor");
                      //setActive("");
                    }}
                    className={mode == "rapor" ? "btn-success" : "btn-warning"}
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faFile}
                    />
                    Raporlar
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={
                      () => setMode("ekstre")
                      //window.open(
                      //  "http://www.verimrapor.com/ayakkabipazari/login.php",
                      //  "_blank"
                      //)
                    }
                    className={
                      "" + (mode == "ekstre" ? " btn-success" : "btn-info")
                    }
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faFilePdf}
                    />
                    Cari Ekstre
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page">
                  <MDBBtn
                    onClick={() => {
                      localStorage.removeItem("name");
                      localStorage.removeItem("pass");
                      localStorage.removeItem("data");
                      localStorage.removeItem("admin");
                      localStorage.removeItem("allData");
                      setTimeout(() => {
                        setName("");
                        setPass("");
                        setLogin(false);
                      }, 500);
                    }}
                    className="btn-danger sign-out"
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faSignOut}
                    />
                    <span>Çıkış Yap</span>
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>

            <MDBBtn
              onClick={() => setFormShow(!formShow)}
              className={formShow != false ? "hide-btn" : "show-btn"}
              style={{ marginRight: 30 }}
            >
              <FontAwesomeIcon icon={faCalendarWeek} />
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                setData([]);
                getData();
                getData(true);
                localStorage.removeItem("gunluk");
                localStorage.removeItem("haftalik");
                localStorage.removeItem("aylik");
                localStorage.removeItem("yillik");
                getAdmin();
                toast("Veriler başarı ile tazelendi!", {
                  position: "bottom-right",
                  autoClose: 3000,
                  closeOnClick: true,
                  pauseOnHover: false,
                  theme: "colored",
                  type: "success",
                });
              }}
              icon="refresh"
              style={{ marginRight: 20, width: 124.422 }}
            >
              <FontAwesomeIcon style={{ marginRight: 10 }} icon={faRefresh} />
              YENİLE
            </MDBBtn>

            <form
              className={
                "d-flex input-group w-auto " +
                (formShow == false ? "hide-form" : "show-form")
              }
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="date"
                className="form-control"
                placeholder="Type query"
                aria-label="Search"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control"
                placeholder="Type query"
                aria-label="Search"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <MDBBtn
                onClick={(e) => {
                  setFormShow(false);
                  setActive("yok");
                }}
                color="primary"
              >
                GETİR
              </MDBBtn>
            </form>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>

      {getPage(mode)}
    </MainContainer>
  );
}
