import React,{useEffect} from 'react'

import Alert from "react-bootstrap/Alert";
import { NumericFormat } from "react-number-format";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { MDBBtn } from 'mdb-react-ui-kit';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



export default function Tables({ stok,setStok }) {
  const [data, setData] = React.useState(null);
  const [data7, set7Data] = React.useState(null);
  const [data20, set20Data] = React.useState(null);
  const [data30, set30Data] = React.useState(null);
  const [data365, set365Data] = React.useState(null);
  const [labels, setLabels] = React.useState(null);
  const [labels7, set7Labels] = React.useState(null);
  const [labels20, set20Labels] = React.useState(null);
  const [labels30, set30Labels] = React.useState(null);
  const [labels365, set365Labels] = React.useState(null);

  const [selectedMode, setSelectedMode] = React.useState(null);


  const [options, setOptions] = React.useState({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: selectedMode==null ? 'Analiz':selectedMode.length==7?'Haftalık':selectedMode.length==30?'Aylık':selectedMode.length==365?'Yıllık':'',
      },
    },
  });

  const getData=async(tarih=7)=>{
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/stok-analiz.php?stok="+stok.LOGICALREF +
        "&mail=" +
        localStorage.getItem("name") +
        "&webpass=" +
        localStorage.getItem("pass")+"&day="+tarih
    ).then((res) => res.json())
      .then(async(res) => {
        let mevcut = [];
        let satis = [];
        let tcikan = [];
        if (tarih == 7) {
          const bk = await setLabels(
            res.data.map((x) => {
              // mevcut.push(parseInt((x.data.Mevcut==null ? 0 : x.data.Mevcut)));
              satis.push(
                parseInt(x.data == null ? 0 : x.data)
              );
              // tcikan.push(parseInt((x.data.Toplam_Cikan==null ? 0 : x.data.Toplam_Cikan)));
              return x.tarih;
            })
          );
          const set = await setData([
        
            {
              label: "Satis",
              data: satis,
              backgroundColor: "green",
            },
          
          ]);



        } else if (tarih == 20) {
          const bk = await set20Labels(
            res.data.map((x) => {
              // mevcut.push(parseInt((x.data.Mevcut==null ? 0 : x.data.Mevcut)));
              satis.push(
                parseInt(x.data== null ? 0 : x.data)
              );
              // tcikan.push(parseInt((x.data.Toplam_Cikan==null ? 0 : x.data.Toplam_Cikan)));
              return x.tarih;
            })
          );
          const set = await set20Data([
            // {
            //   label: "Mevcut",
            //   data: mevcut,
            //   backgroundColor: "red",
            // },
            {
              label: "Satis",
              data: satis,
              backgroundColor: "green",
            },
            // {
            //   label: "Toplam Cikan",
            //   data: tcikan,
            //   backgroundColor: "blue",
            // },
          ]);
        } else if (tarih == 30) {
          const bk = await set30Labels(
            res.data.map((x) => {
              // mevcut.push(parseInt((x.data.Mevcut==null ? 0 : x.data.Mevcut)));
              satis.push(
                parseInt(x.data== null ? 0 : x.data)
              );
              // tcikan.push(parseInt((x.data.Toplam_Cikan==null ? 0 : x.data.Toplam_Cikan)));
              return x.tarih;
            })
          );
          const set = await set30Data([
            // {
            //   label: "Mevcut",
            //   data: mevcut,
            //   backgroundColor: "red",
            // },
            {
              label: "Satis",
              data: satis,
              backgroundColor: "green",
            },
            // {
            //   label: "Toplam Cikan",
            //   data: tcikan,
            //   backgroundColor: "blue",
            // },
          ]);
        } else if (tarih == 365) {
          const bk = await set365Labels(
            res.data.map((x) => {
              // mevcut.push(parseInt((x.data.Mevcut==null ? 0 : x.data.Mevcut)));
              satis.push(
                parseInt(x.data== null ? 0 : x.data)
              );
              // tcikan.push(parseInt((x.data.Toplam_Cikan==null ? 0 : x.data.Toplam_Cikan)));
              return x.tarih;
            })
          );
          const set = await set365Data([
            // {
            //   label: "Mevcut",
            //   data: mevcut,
            //   backgroundColor: "red",
            // },
            {
              label: "Satis",
              data: satis,
              backgroundColor: "green",
            },
            // {
            //   label: "Toplam Cikan",
            //   data: tcikan,
            //   backgroundColor: "blue",
            // },
          ]);
        }
        
      });
  }
  useEffect(() => {
    if(data==null && stok!=null){
      getData(7);
      getData(20);
      getData(30);
      getData(365);

    }else{
      setSelectedMode({ labels: labels, data: data });
    }
  }, [data]);


  return (
    <>
      {data != null && (
        <div
          className="ekstre-box"
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            paddingLeft: 10,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: 20,
              flexWrap: "wrap",
              marginBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            className="info-box2"
          ></div>
          <div
            className="info-box2"
            style={{
              marginTop: 20,
              flexWrap: "wrap",
              marginBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          ></div>
        </div>
      )}

      <div className="chart-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            marginTop: 30,
          }}
        >
          <span
            style={{
              position: "absolute",
              background: "red",
              borderRadius: "100%",
              padding: "3px 12px",
              fontWeight: "bold",
              top: 0,
              color: "white",
            }}
          >
            {parseInt(stok.Mevcut)}
          </span>
          <img
            src={"" + stok.DosyaAdi}
            height={120}
          />
          <h3>
            {stok.StokAdi} ({stok.StokKodu})
          </h3>
        </div>
        {selectedMode != null ? (
          <>
            <Bar
              options={options}
              data={{
                labels: selectedMode.labels,
                datasets: selectedMode.data,
              }}
            />
          </>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 50,
            }}
          >
            <center>
              <img
                height={250}
                width={250}
                src="https://tedarikci.ayakkabipazari.com/loading.gif?asdf=123"
              />
              <h1>Analiz Yükleniyor..</h1>
            </center>
          </div>
        )}
        <div
          className="analiz-button-list"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <MDBBtn
              className={
                selectedMode != null && selectedMode.labels == labels
                  ? "btn-success"
                  : ""
              }
              onClick={() => setSelectedMode({ labels: labels, data: data })}
            >
              Haftalık
            </MDBBtn>
            {data20 != null && (
              <MDBBtn
                className={
                  selectedMode != null && selectedMode.labels == labels20
                    ? "btn-success"
                    : ""
                }
                onClick={() =>
                  setSelectedMode({ labels: labels20, data: data20 })
                }
              >
                20 Günlük
              </MDBBtn>
            )}
            {data30 != null && (
              <MDBBtn
                className={
                  selectedMode != null && selectedMode.labels == labels30
                    ? "btn-success"
                    : ""
                }
                onClick={() =>
                  setSelectedMode({ labels: labels30, data: data30 })
                }
              >
                Aylık
              </MDBBtn>
            )}
            {data365 != null && (
              <MDBBtn
                className={
                  selectedMode != null && selectedMode.labels == labels365
                    ? "btn-success"
                    : ""
                }
                onClick={() =>
                  setSelectedMode({ labels: labels365, data: data365 })
                }
              >
                Yıllık
              </MDBBtn>
            )}
            {data20 == null || data30 == null || data365 == null ? (
              <img src="https://tedarikci.ayakkabipazari.com/loading.gif?asdf=123" width={50} />
            ) : (
              <></>
            )}
          </div>
          {selectedMode != null && (
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              Toplam :{" "}
              {selectedMode.data[0].data.reduce((acc, el) => {
                return acc+el;
              }, 0)}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
