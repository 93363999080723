import React from 'react'
import styled from 'styled-components'
import '../login.css'
const LoginContainer = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url(https://ayakkabipazari.entegroid.com/another/tedarikci_login_bg.jpg)
    no-repeat;
  background-size: cover;
  background-position: center;

  .wrapper {
    width: 420px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 10px;
    @media only screen and (max-width: 800px) {
      backdrop-filter: unset;
      background-color: rgba(0, 0, 0, 0.75);
    }
    padding: 30px 40px;
  }

  .wrapper h1 {
    font-size: 36px;
    text-align: center;
  }

  .wrapper .input-box {
    width: 100%;
    height: 60px;
    position: relative;
    margin-top: 20px;
  }

  .input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 40px 20px 20px;
  }

  .input-box input::placeholder {
    color: #fff;
  }

  .wrapper .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: 15px 5px 15px;
  }

  .remember-forgot label input {
    accent-color: #fff;
    margin-right: 3px;
  }

  .remember-forgot a {
    color: #fff;
    text-decoration: none;
  }

  .remember-forgot a:hover {
    text-decoration: none;
    color: black;
  }

  .wrapper .btn {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    color: #333;
    font-weight: 700;
  }

  .wrapper .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
  }

  .register-link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }

  .register-link p a:hover {
    text-decoration: none;
    color: black;
  }

  .error {
    width: 100%;
    border-radius: 20px;
    background-color: rgba(242, 0, 0, 0.2);
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 15px 10px;
  }
`;
export default function Login({name,setName,setAdmin,pass,setPass,setLogin}) {
  const [error, setError] = React.useState("yok");
  const [loading, setLoading] = React.useState(true);
  
  
  const sendLogin=async(e="")=>{
    if(e!=""){
      e.preventDefault()

    }
    

    try {
      fetch("https://api.tedarikci.ayakkabipazari.com/login.php?mail="+name+"&webpass="+pass).then(res=>res.json()).then(res=>{
        setLoading(false);
        if(res.error==true){
          localStorage.removeItem("name");
          localStorage.removeItem("pass");
          localStorage.removeItem("admin");
          setError("Kullanıcı adı veya parola hatalı");
        }else{
          if (res.admin == true) {
            localStorage.setItem("ACname", res.data[0].HESAPADI);
            localStorage.setItem("name", res.data[0].MAIL);
            localStorage.setItem("pass", res.data[0].WebPass);
            localStorage.setItem("admin", JSON.stringify(res.data));
            localStorage.setItem("adminName", name);
            localStorage.setItem("adminPass", pass);
            setAdmin(true);
          } else {
            localStorage.setItem("ACname", res.data.HESAPADI);
            localStorage.setItem("name", name);
            localStorage.setItem("pass", pass);
          }
          
          setLogin(true)
          setError("yok")
        }
      });
      
    } catch (error) {
      setError("Kullanıcı adı veya parola hatalı");
    }
  }
  React.useLayoutEffect(() => {
    if (name != "" && pass != "") {

      sendLogin();
    }else{
      setLoading(false);

    }
  }, []);


  return (
    <>
      {!loading ? (
        <LoginContainer>
          <div className="wrapper">
            <form method="POST" onSubmit={sendLogin}>
              <h1>Giriş </h1>
              <div className="input-box">
                <input
                  type="email"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="E Posta"
                  required
                />
              </div>
              <div className="input-box">
                <input
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  type="password"
                  name="password"
                  placeholder="Parola"
                  required
                />
              </div>
              <div className="remember-forgot">
                <label></label>
              </div>

              {error != "yok" ? <div className="error">{error}</div> : <></>}
              <button type="submit" className="btn">
                Giriş Yap
              </button>

              <div className="register-link">
                <p>
                  Copyright &copy;{" "}
                  <a href="https://dehasoft.com.tr"> Dehasoft</a> Tüm hakları
                  saklıdır.
                </p>
              </div>
            </form>
          </div>
        </LoginContainer>
      ) : (
        <></>
      )}
    </>
  );
}
