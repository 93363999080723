import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState} from 'react'
import styled from 'styled-components'
import { faArrowDown, faBars, faLock, faShield, faTimes } from '@fortawesome/free-solid-svg-icons';
const PopMenuContainer = styled.div`
  .s-btn {
    position: fixed;
    font-size: 20px;
    border-radius: 30%;
    left: 20px;
    z-index: 999;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px 1px #f1f1f1;
    span {
      display: none;
    }
    transition: 0.5s;
  }
  .btn-first {
    transform: scale(${(props) => (props.open ? 0 : 1)});
    bottom: ${(props) => (!props.open ? "15" : "-200")}px;
  }
  .btn-second {
    transform: scale(${(props) => (props.open ? 1 : 0)});
    bottom: ${(props) => (props.open ? "15" : "-200")}px;
  }
  .pop-menu {
    transition: 0.5s all;
    position: fixed;
    bottom: ${(props) => (props.open ? "70" : "-200")}px;
    padding: 5px 2px;
    box-shadow: 0px 0px 1px #285192;
    border-radius: 15px;
    width: 200px;
    background-color: #d8dfeb;
    transform: scale(${(props) => (props.open ? 1 : 0)});
    left: 20px;
    z-index: 99899;
    .item{
      display:flex;    z-index: 99899;

      align-items:center;
      border-radius: 10px;
      padding:10px;
      cursor:pointer;
      transition: 0.2s;
      &:hover{
        background-color: #285192;
        color: #fff;
      }
      span:first-child{
        margin-right: 10px;
        font-size: 20px;
      }
    }
  }

  .popup-form{
    position: fixed;
    top:0;
    left:0;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    padding:0;
    margin:0;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index: 99999;
    .form{
      position: relative;
      .p-close{
        position: absolute;
        right: 25px;
        top: 10px;
        cursor: pointer;
        font-size: 30px;
      }

      width: 50%;
      min-height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      flex-direction:column;
      border-radius: 40px;
      padding: 40px;
      box-shadow: 0px 0px 3px 1px #f1f1f1;
      h1{
        margin-bottom:40px;
      }
      button{
        width:60%;
        font-size:17px;
        padding: 10px 0;
      }
      input{
        width: 60%;
        padding: 15px 10px;
        margin-bottom:10px;
        border-radius: 10px;
        background: #fff;
        border:0;
        box-shadow: 0px 0px 5px 3px #f1f1f1;
        outline: none;
        margin-bottom: 10px;
        &:last-child{
        margin-bottom: 10px;
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    .form {
      width: 95%;
      input,button{
        width: 90%;
      }
      button{
        margin-top:10px;
      }
    }
  }
`;

export default function PopMenu({ setName, setPass, setLogin, adminOpen,setAdminOpen }) {
  const [showPopMenu, setShowPopMenu] = useState(false);
  const [changePass, setChangePass] = useState(false);

  const [oldPw, setOldPW] = useState("");
  const [newPw, setNewPW] = useState("");
  const [confirmPw, setConfirmPW] = useState("");
  const [pwError, setPwError] = useState(false);

  const changePw = async () => {
    if (newPw !== confirmPw) {
      setPwError(true);
    } else if (oldPw != localStorage.getItem("pass")) {
      setPwError(true);
    } else {
      fetch(
        "https://api.tedarikci.ayakkabipazari.com/cari-sifre.php?" +
          "mail=" +
          localStorage.getItem("name") +
          "&webpass=" +
          oldPw +
          "&newpass=" +
          newPw
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.error == false) {
            localStorage.removeItem("name");
            localStorage.removeItem("pass");
            localStorage.removeItem("allData");
            setTimeout(() => {
              setName("");
              setPass("");
              setLogin(false);
            }, 500);
          }
        });
    }
  };
  return (
    <PopMenuContainer open={showPopMenu}>
      <button
        onClick={() => setShowPopMenu(!showPopMenu)}
        className="s-btn btn btn-first btn-secondary"
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <button
        onClick={() => setShowPopMenu(!showPopMenu)}
        className="s-btn btn btn-second btn-secondary"
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="pop-menu">
        <div
          className="item"
          onClick={() => {
            setChangePass(true);
          }}
        >
          <span>
            <FontAwesomeIcon icon={faLock} />
          </span>
          <span>Şifremi Değiştir</span>
        </div>
        {localStorage.getItem("admin") == null ? (
          <></>
        ) : (
          <div
            className="item"
            onClick={() => {
              setShowPopMenu(!showPopMenu);
              setAdminOpen(!adminOpen);
            }}
          >
            <span>
              <FontAwesomeIcon icon={faShield} />
            </span>
            <span>Yönetici Menüsü</span>
          </div>
        )}
      </div>

      {changePass && (
        <div className="popup-form" onClick={() => setChangePass(false)}>
          <div className="form" onClick={(e) => e.stopPropagation()}>
            <div onClick={() => setChangePass(false)} className="p-close">
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <h1>
              {" "}
              <FontAwesomeIcon icon={faLock} /> Şifreni Değiştir
            </h1>
            <input
              type="password"
              value={oldPw}
              onChange={(e) => setOldPW(e.target.value)}
              placeholder="Eski Şifreniz"
            />
            <input
              type="password"
              value={newPw}
              onChange={(e) => setNewPW(e.target.value)}
              placeholder="Yeni Şifreniz"
            />
            <input
              type="password"
              value={confirmPw}
              onChange={(e) => setConfirmPW(e.target.value)}
              placeholder="Tekrar Yeni Şifreniz"
            />
            <div
              className="alert alert-danger"
              style={
                pwError
                  ? { display: "block", width: "60%" }
                  : { display: "none" }
              }
            >
              Yeni şifre alanları uyuşmuyor veya eski şifreniz hatalı
            </div>
            <button className="btn btn-success" onClick={changePw}>
              Şifremi Kaydet
            </button>
          </div>
        </div>
      )}
    </PopMenuContainer>
  );
}
