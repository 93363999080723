import React from 'react'
import Login from "./pages/Login";
import Main from "./pages/Main";
import Ekstre from "./pages/Ekstre";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { MDBBtn } from 'mdb-react-ui-kit';
import { ToastContainer, toast } from "react-toastify";
import company from './company.png'
import "react-toastify/dist/ReactToastify.css";
export default function App() {
  const [page,setPage]=React.useState(false)
  const [login, setLogin] = React.useState(false);
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [admin, setAdmin] = React.useState(localStorage.getItem('admin')==null ? false:true);
  const [name, setName] = React.useState(localStorage.getItem("name")!=null?localStorage.getItem("name"):'');
  const [pass, setPass] = React.useState(localStorage.getItem("pass")!=null?localStorage.getItem("pass"):'');
  const [notification,setNotification]=React.useState(localStorage.getItem('ayakkabipazarinotification')==null ? true:false)
 
  return (
    <>
      <ToastContainer />
      {login ? (
        <>
          <div className='no-print company'><img src={company} /></div>
          <Main
            adminOpen={adminOpen}
            setAdminOpen={setAdminOpen}
            setLogin={setLogin}
            setName={setName}
            setPass={setPass}
          />
          <div
            className="no-print"
            onClick={() => window.open("https://dehasoft.com.tr", "_blank")}
            style={{
              cursor: "pointer",
              marginTop: 130,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              height={60}
              src={"https://dehasoft.com.tr/images/LOGO-p-500.png"}
            />
          </div>
          <div
            className="no-print"
            onClick={() => window.open("https://dehasoft.com.tr", "_blank")}
            style={{
              cursor: "pointer",
              fontSize: 17,
              marginTop: 0,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Tüm Hakları Saklıdır &copy; {new Date().getFullYear()}
          </div>
          
        </>
      ) : (
        <Login
          name={name}
          setName={setName}
          pass={pass}
          setAdmin={setAdmin}
          setPass={setPass}
          setLogin={setLogin}
        />
      )}
    </>
  );
}
