import React, { useEffect } from "react";

import Alert from "react-bootstrap/Alert";
import { NumericFormat } from "react-number-format";
import { faArrowDown, faArrowUp, faCalendarWeek, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { MDBBtn } from "mdb-react-ui-kit";
import loadingGif from "../loading.gif";
export default function Tables({adminData,setAdminData}) {
  const [search, setSearch] = React.useState("");
  
  const filtered=data=>{
    return data.filter((x)=>x.HESAPADI.toLowerCase().includes(search.toLowerCase()))
  }
  const [sorted, setSorted] = React.useState({ row:0, mode: "desc" });
  const [oldSorted, setOldSorted] = React.useState("0");
  const sortData = async (col, mode = "asc") => {
    let sortedeb = adminData.slice().sort((a, b) => {
      if (mode != "asc") {
        b.Total = b.Total == null ? 0 : b.Total;
        a.Total = a.Total == null ? 0 : a.Total;
        a.SatisGunluk = a.SatisGunluk==undefined || a.SatisGunluk == null ? 0 : a.SatisGunluk;
        b.SatisGunluk = b.SatisGunluk==undefined || b.SatisGunluk == null ? 0 : b.SatisGunluk;

        a.SatisHaftalik = a.SatisHaftalik==undefined || a.SatisHaftalik == null ? 0 : a.SatisHaftalik;
        b.SatisHaftalik = b.SatisHaftalik==undefined || b.SatisHaftalik == null ? 0 : b.SatisHaftalik;

        a.SatisAylik = a.SatisAylik==undefined || a.SatisAylik == null ? 0 : a.SatisAylik;
        b.SatisAylik = b.SatisAylik==undefined || b.SatisAylik == null ? 0 : b.SatisAylik;
        
        a.SatisYillik = a.SatisYillik==undefined || a.SatisYillik == null ? 0 : a.SatisYillik;
        b.SatisYillik = b.SatisYillik==undefined || b.SatisYillik == null ? 0 : b.SatisYillik;

        switch (col) {
          case 0:
            return parseInt(b.Total) - parseInt(a.Total);
            break;
          case 1:
            // b.HESAPADI ve a.HESAPADI
            return b.HESAPADI.toLowerCase().localeCompare(
              a.HESAPADI.toLowerCase()
            );
            break;
          case 2:
            return parseInt(b.OzelKod3_FID) - parseInt(a.OzelKod3_FID);
            break;
          case 521:
            return parseInt(b.SatisGunluk) - parseInt(a.SatisGunluk);
            break;
          case 522:
            return parseInt(b.SatisHaftalik) - parseInt(a.SatisHaftalik);
            break;
          case 523:
            return parseInt(b.SatisAylik) - parseInt(a.SatisAylik);
            break;
          case 524:
            return parseInt(b.SatisYillik) - parseInt(a.SatisYillik);
            break;
        }
      } else {
        switch (col) {
          case 0:
            return parseInt(a.Total) - parseInt(b.Total);
            break;
          case 1:
            return a.HESAPADI.toLowerCase().localeCompare(
              b.HESAPADI.toLowerCase()
            );
            break;
          case 2:
            return parseInt(a.OzelKod3_FID) - parseInt(b.OzelKod3_FID);
            break;
          case 521:
            return parseInt(a.SatisGunluk) - parseInt(b.SatisGunluk);
            break;
          case 522:
            return parseInt(a.SatisHaftalik) - parseInt(b.SatisHaftalik);
            break;
          case 523:
            return parseInt(a.SatisAylik) - parseInt(b.SatisAylik);
            break;
          case 524:
            return parseInt(a.SatisYillik) - parseInt(b.SatisYillik);
            break;
        }
      }
    });
    setOldSorted({ row: col, mode: mode });
    setSorted({ row: col, mode: mode });
    setAdminData(sortedeb);
  };

  const [gunluk,setGunluk]=React.useState(localStorage.getItem('gunluk')!=undefined && localStorage.getItem('gunluk')!=null ? adminData:null)
  const [gunlukLoading,setGunlukLoading]=React.useState(false)

  const getGunluk = async () => {
    setGunlukLoading(true)
    setGunluk(null)
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/admin-analiz.php?day=0" +
        "&mail=" +
        localStorage.getItem("adminName") +
        "&webpass=" +
        localStorage.getItem("adminPass")
    )
      .then((res) => res.json())
      .then(async (res) => {
        setGunlukLoading(false)
        setAdminData()
        let newAdminData=[];
        const set=await adminData.map((x) => {
          if (
            res.data.filter((y) => y.HESAPADI == x.HESAPADI) != null &&
            res.data.filter((y) => y.HESAPADI == x.HESAPADI).length!=0
          ){
            newAdminData.push({
              ...x,
              SatisGunluk: res.data.filter((y) => y.HESAPADI == x.HESAPADI)[0]
                .SatisGunluk,
            });
          }
            
        });
        setAdminData(newAdminData);
        setGunluk(res.data)
        localStorage.setItem("admin", JSON.stringify(newAdminData));
        localStorage.setItem("gunluk", "true");
      }).catch(err=>{
        setGunlukLoading(false)
      });
  };

  const [haftalik, setHaftalik] = React.useState(localStorage.getItem('haftalik')!=undefined && localStorage.getItem('haftalik')!=null ? adminData:null);
  const [haftalikLoading, setHaftalikLoading] = React.useState(false);

  const getHaftalik = async () => {
    setHaftalikLoading(true);
    setHaftalik(null);
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/admin-analiz.php?day=7" +
        "&mail=" +
        localStorage.getItem("adminName") +
        "&webpass=" +
        localStorage.getItem("adminPass")
    )
      .then((res) => res.json())
      .then(async (res) => {
        setHaftalikLoading(false);
        setAdminData();
        let newAdminData = [];
        const set = await adminData.map((x) => {
          if (
            res.data.filter((y) => y.HESAPADI == x.HESAPADI) != null &&
            res.data.filter((y) => y.HESAPADI == x.HESAPADI).length != 0
          ) {
            newAdminData.push({
              ...x,
              SatisHaftalik: res.data.filter((y) => y.HESAPADI == x.HESAPADI)[0]
                .SatisHaftalik,
            });
          }
        });
        setAdminData(newAdminData);
        setHaftalik(res.data);
        localStorage.setItem("admin", JSON.stringify(newAdminData));
        localStorage.setItem("haftalik", 'true');
      })
      .catch((err) => {
        setHaftalikLoading(false);
      });
  };

  const [aylik, setAylik] = React.useState(
    localStorage.getItem("aylik") != undefined &&
      localStorage.getItem("aylik") != null
      ? adminData
      : null
  );
  const [aylikLoading, setAylikLoading] = React.useState(false);

  const getAylik = async () => {
    setAylikLoading(true);
    setAylik(null);
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/admin-analiz.php?day=30" +
        "&mail=" +
        localStorage.getItem("adminName") +
        "&webpass=" +
        localStorage.getItem("adminPass")
    )
      .then((res) => res.json())
      .then(async (res) => {
        setAylikLoading(false);
        setAdminData();
        let newAdminData = [];
        const set = await adminData.map((x) => {
          if (
            res.data.filter((y) => y.HESAPADI == x.HESAPADI) != null &&
            res.data.filter((y) => y.HESAPADI == x.HESAPADI).length != 0
          ) {
            newAdminData.push({
              ...x,
              SatisAylik: res.data.filter((y) => y.HESAPADI == x.HESAPADI)[0]
                .SatisAylik,
            });
          }
        });
        setAdminData(newAdminData);
        setAylik(res.data);
        localStorage.setItem("admin", JSON.stringify(newAdminData));
        localStorage.setItem("aylik", "true");
      })
      .catch((err) => {
        setAylikLoading(false);
      });
  };

  
  

  const [yillik, setYillik] = React.useState(
    localStorage.getItem("yillik") != undefined &&
      localStorage.getItem("yillik") != null
      ? adminData
      : null
  );
  const [yillikLoading, setYillikLoading] = React.useState(false);

  const getYillik = async () => {
    setYillikLoading(true);
    setYillik(null);
    fetch(
      "https://api.tedarikci.ayakkabipazari.com/admin-analiz.php?day=365" +
        "&mail=" +
        localStorage.getItem("adminName") +
        "&webpass=" +
        localStorage.getItem("adminPass")
    )
      .then((res) => res.json())
      .then(async (res) => {
        setYillikLoading(false);
        setAdminData();
        let newAdminData = [];
        const set = await adminData.map((x) => {
          if (
            res.data.filter((y) => y.HESAPADI == x.HESAPADI) != null &&
            res.data.filter((y) => y.HESAPADI == x.HESAPADI).length != 0
          ) {
            newAdminData.push({
              ...x,
              SatisYillik: res.data.filter((y) => y.HESAPADI == x.HESAPADI)[0]
                .SatisYillik,
            });
          }
        });
        setAdminData(newAdminData);
        setYillik(res.data);
        localStorage.setItem("admin", JSON.stringify(newAdminData));
        localStorage.setItem("yillik", "true");
      })
      .catch((err) => {
        setYillikLoading(false);
      });
  };

  useEffect(() => {
    if (
      localStorage.getItem("gunluk") == undefined ||
      localStorage.getItem("gunluk") == null
    ) {
      setGunluk(null);
    }
    if (
      localStorage.getItem("haftalik") == undefined ||
      localStorage.getItem("haftalik") == null
    ) {
      setHaftalik(null);
    }
    if (
      localStorage.getItem("aylik") == undefined ||
      localStorage.getItem("aylik") == null
    ) {
      setAylik(null);
    }

    if (
      localStorage.getItem("yillik") == undefined ||
      localStorage.getItem("yillik") == null
    ) {
      setYillik(null);
    }
  }, [adminData]);


  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 20, display: "flex" }}>
        <input
          type="text"
          placeholder="Hesap Adı Ara"
          className="form-control no-print"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="table-container">
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th
                onClick={(e) =>
                  sortData(
                    1,
                    sorted.row == 1 && sorted.mode == "asc" ? "desc" : "asc"
                  )
                }
              >
                Hesap Adı
                {sorted.row == 1 ? (
                  sorted.mode != "desc" ? (
                    <FontAwesomeIcon icon={faArrowUp} />
                  ) : (
                    <FontAwesomeIcon icon={faArrowDown} />
                  )
                ) : (
                  <></>
                )}
              </th>
              <th
                onClick={(e) => {
                  if (gunluk != null) {
                    sortData(
                      521,
                      sorted.row == 521 && sorted.mode == "asc" ? "desc" : "asc"
                    );
                  }
                }}
                style={{ width: 160 }}
              >
                {gunlukLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img height={40} src={loadingGif} />
                    <div>Yükleniyor...</div>
                  </div>
                ) : gunluk == null ? (
                  <MDBBtn
                    onClick={() => {
                      getGunluk();
                    }}
                    disabled={
                      gunlukLoading ||
                      haftalikLoading ||
                      aylikLoading ||
                      yillikLoading
                        ? true
                        : false
                    }
                    className={true == "0" ? "btn-success" : ""}
                  >
                    Günlük <FontAwesomeIcon icon={faDownload} />
                  </MDBBtn>
                ) : (
                  <>
                    Günlük Satış
                    {sorted.row == 521 ? (
                      sorted.mode != "desc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </th>
              <th
                onClick={(e) => {
                  if (haftalik != null) {
                    sortData(
                      522,
                      sorted.row == 522 && sorted.mode == "asc" ? "desc" : "asc"
                    );
                  }
                }}
                style={{ width: 170 }}
              >
                {haftalikLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img height={40} src={loadingGif} />
                    <div>Yükleniyor...</div>
                  </div>
                ) : haftalik == null ? (
                  <MDBBtn
                    disabled={
                      gunlukLoading ||
                      haftalikLoading ||
                      aylikLoading ||
                      yillikLoading
                        ? true
                        : false
                    }
                    onClick={() => {
                      getHaftalik();
                    }}
                    className={true == "0" ? "btn-success" : ""}
                  >
                    Haftalık <FontAwesomeIcon icon={faDownload} />
                  </MDBBtn>
                ) : (
                  <>
                    Haftalık Satış
                    {sorted.row == 522 ? (
                      sorted.mode != "desc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </th>
              <th
                onClick={(e) => {
                  if (aylik != null) {
                    sortData(
                      523,
                      sorted.row == 523 && sorted.mode == "asc" ? "desc" : "asc"
                    );
                  }
                }}
                style={{ width: 170 }}
              >
                {aylikLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img height={40} src={loadingGif} />
                    <div>Yükleniyor...</div>
                  </div>
                ) : aylik == null ? (
                  <MDBBtn
                    disabled={
                      gunlukLoading ||
                      haftalikLoading ||
                      aylikLoading ||
                      yillikLoading
                        ? true
                        : false
                    }
                    onClick={() => {
                      getAylik();
                    }}
                    className={true == "0" ? "btn-success" : ""}
                  >
                    Aylık <FontAwesomeIcon icon={faDownload} />
                  </MDBBtn>
                ) : (
                  <>
                    Aylık Satış
                    {sorted.row == 523 ? (
                      sorted.mode != "desc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </th>
              <th
                onClick={(e) => {
                  if (yillik != null) {
                    sortData(
                      524,
                      sorted.row == 524 && sorted.mode == "asc" ? "desc" : "asc"
                    );
                  }
                }}
                style={{ width: 170 }}
              >
                {yillikLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img height={40} src={loadingGif} />
                    <div>Yükleniyor...</div>
                  </div>
                ) : yillik == null ? (
                  <MDBBtn
                    disabled={
                      gunlukLoading ||
                      haftalikLoading ||
                      aylikLoading ||
                      yillikLoading
                        ? true
                        : false
                    }
                    onClick={() => {
                      getYillik();
                    }}
                    className={true == "0" ? "btn-success" : ""}
                  >
                    Yıllık <FontAwesomeIcon icon={faDownload} />
                  </MDBBtn>
                ) : (
                  <>
                    Yıllık Satış
                    {sorted.row == 524 ? (
                      sorted.mode != "desc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </th>
              <th style={{ width: 220 }}>Durum</th>
            </tr>
          </thead>
          <tbody>
            {filtered(adminData).map((x, index) => {
              console.log("a", x.SatisGunluk);
              return (
                <tr key={"tab-" + index}>
                  <td>{x.HESAPADI}</td>
                  <td
                    style={{
                      filter: gunluk == null ? "blur(" + 5 + "px)" : "",
                    }}
                  >
                    {parseInt(
                      x.SatisGunluk != undefined && x.SatisGunluk != null
                        ? x.SatisGunluk
                        : 0
                    )}
                  </td>
                  <td
                    style={{
                      filter: haftalik == null ? "blur(" + 5 + "px)" : "",
                    }}
                  >
                    {parseInt(
                      x.SatisHaftalik != undefined && x.SatisHaftalik != null
                        ? x.SatisHaftalik
                        : 0
                    )}
                  </td>
                  <td
                    style={{
                      filter: aylik == null ? "blur(" + 5 + "px)" : "",
                    }}
                  >
                    {parseInt(
                      x.SatisAylik != undefined && x.SatisAylik != null
                        ? x.SatisAylik
                        : 0
                    )}
                  </td>
                  <td
                    style={{
                      filter: yillik == null ? "blur(" + 5 + "px)" : "",
                    }}
                  >
                    {parseInt(
                      x.SatisYillik != undefined && x.SatisYillik != null
                        ? x.SatisYillik
                        : 0
                    )}
                  </td>
                  <td>
                    <MDBBtn
                      onClick={() => {
                        localStorage.setItem("name", x.MAIL);
                        localStorage.setItem("pass", x.WebPass);
                        localStorage.setItem("ACname", x.HESAPADI);
                        setTimeout(() => {
                          window.location.reload(false);
                        }, 1000);
                      }}
                    >
                      HESABA GEÇİŞ YAP
                    </MDBBtn>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
